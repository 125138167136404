import React from "react";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className="work-process-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">OUR SIMPLE PROCESS</h6>
            <h2 className="title">
              {/* World <span>Best Step</span> Our It Process */}
              OUR <span>CORE</span> VALUES
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/9.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">01</p>
                  {/* <h5 className='mb-3'>Software Research</h5> */}
                  <p className="content">
                    To provide extraordinary service in an extraordinary way
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/10.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count"> 02</p>
                  {/* <h5 className="mb-3">Create Programs</h5> */}
                  <p className="content">
                    To improve the quality of life in the communities we serve.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/11.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count"> 03</p>
                  {/* <h5 className="mb-3">Develope Software</h5> */}
                  <p className="content">
                    To help our customers, to support them, and to make their
                    lives easier
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/12.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="process-count">04</p>
                  {/* <h5 className="mb-3">Shop software</h5> */}
                  <p className="content">
                    To help our clients and communities build a better future
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
