import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/about/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  {/* It Is A Critical Component Of <span>Modern</span> Businesses */}
                  OUR <span>MISSION</span>
                </h2>
                <p className="content mb-4 mb-xl-5">
                  our mission is to be the financial company of reference,
                  meeting customers’ needs by deploying timely, innovative, and
                  exceptional services while rewarding stakeholders with
                  excellent returns in a manner that consistently exceeds their
                  expectations
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/2.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>OUR VISION</h5>
                        <p>
                          Our vision is to be the best customer-celebrated
                          financial services company in Nigeria
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/3.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>OUR OBJECTIVE</h5>
                        <p>
                          <ul>
                            <li>
                              To make living easier for our customers by
                              offering a full range of professional financial
                              services.
                            </li>
                            {/* <li>
                              Continuously providing consumer and personal loans
                              to Nigerian workers in the easiest way possible to
                              enable them to meet various financial obligations
                            </li> */}
                            <li>
                              Attract, develop, and retain productive man-power.
                            </li>
                            {/* <li>
                              Establish a market presence that assures superior
                              returns both in the short and medium term, growth,
                              and considerable market share, which will
                              ultimately translate to long-term business
                              success.
                            </li> */}
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
